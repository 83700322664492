.App {
  padding-left: 10vw;
  padding-right: 10vw;
  font-family: 'Jost', sans-serif;
}

@media (hover: none),
(pointer:coarse),
(-moz-touch-enabled: 1) {
  .scroll-horizontal {
    overflow: scroll !important;
    overflow: overlay !important;
    overflow-x: scroll !important;
    overflow-x: overlay !important;
    overflow-y: hidden !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }
}

@media(max-width:1100px) {
  .App {
    padding-left: 5%;
    padding-right: 5%;
    font-family: 'Jost', sans-serif;
  }

  .webWorksWrapper {
    width: 100%;
    height: 500px;
    -webkit-overflow-scrolling: touch
  }

  .webWorkBox {
    width: 300px;
    height: 300px;
    margin-right: 24px;
    text-align: center;
    -webkit-overflow-scrolling: touch
  }
}

.webWorkBox {
  width: 300px;
  height: 300px;
  margin-right: 24px;
  text-align: center;
}

.webWorksWrapper {
  width: 100%;
  height: 500px;
}

.bg {
  background-color: #f1f1f1
}