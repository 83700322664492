.aboutContainer {
    padding-top: 200px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.aboutImage {
    width: 169px;
    height: auto;
}

.aboutTitle {
    font-family: Jost;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.67px;
    text-align: center;
    color: #919090;
}

.aboutContent {
    font-family: Jost;
    font-size: 14px;
    text-align: justify;
    color: #919090;
    padding-left: 15%;
    padding-right: 15%;
}

@media(max-width:1100px) {
    .aboutContent {
        padding-left: 3%;
        padding-right: 3%;
    }
}