.webWorkContainer {
    padding-top: 145px;
}

.webWorksTitle {
    font-family: Jost;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.5px;
    text-align: center;
    color: #919090;
    padding-bottom: 48px
}

.webBox {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
}

.webWorksImage {
    padding-top: 15%;
    width: 80%;
}

.webTitleBox {
    text-align: center;
    padding-top: 20px;
}

.workLittleTitle {
    font-size: 14px;
    font-weight: 400;
}

.webLink {
    text-decoration: none;
    color: #909090
}