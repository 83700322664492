.footerWrapper {
    margin-top: 200px;
    padding-top: 48px;
    padding-bottom: 48px;
    text-align: center;
    border-top: solid 1px rgba(151, 151, 151, 0.5);
    color: #909090
}

@media(max-width:1100px) {
    .footerWrapper {
        padding-bottom: 60px;
    }
}